
.interior {
    background-image: linear-gradient(to top,  rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./interior.jpg');
    background-size: cover;
    background-position: center;
}
.exterior {
    background-image: linear-gradient(to top,  rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./exterior.jpg');
    background-size: cover;
    background-position: center;
}
.designer {
    background-image: linear-gradient(to top,  rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./designer.jpg');
    background-size: cover;
    background-position: center;
}

.servicePageBox {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 100px;

    .typeOfService {
        margin-top: 40px;
        
        

       

        .typeOfService-item {
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            width: 100%;
            height: 215px;
            background-color: #29241C;
            color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            padding: 20px;
            cursor: pointer;

            .typeOfService-item-hidden {
                    display: none;
                }
            &-content {
                margin-top: 10px;
                border: 2px solid #29241C;
                border-radius: 10px;
                padding: 15px;
                font-size: 18px;
                transition: all 0.3s;
                height: 215px;

                .content-hidden {
                    display: none;
                    height: 0;
                    opacity: 0;
                }
               


                .content-overflow {
                    height: 205px;
                    overflow: auto;
                    

                    &::-webkit-scrollbar {
                        width: 5px;
                        height: 0;
                        background: white;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: #2D2A25;
                        border-radius: 100px;
                    }
                    &::-webkit-scrollbar-track {
                        width: 5px;
                        background: #e4e0e0;
                        border-radius: 100px;
                    }
                }
               

            }
            

           
            
            
        }
    
    }

}


.services-title {
    font-size: 40px;
    text-align: center;
    margin: 0;
    padding-top: 25px;
    color: rgb(0, 0, 0);
}

.blockWhy {
    background-color: #2D2A25;
    color: white;
    font-size: 17px;
    padding: 20px 0;
    margin-bottom: 100px;

    .whyWe-box {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        @media (min-width: 750px) {
            flex-direction: row;
            
        }

        .benefitUs {
            p {
                color: #8C6239;
                font-weight: 600;
            }
            ul {
                padding: 0;
                li {
                    line-height: 1.6;
                }
            }
        }
    }

}