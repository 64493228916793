.pageCatalog-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
    
    @media (min-width:550px) {
      flex-direction: row;
      
      align-items: inherit;
      
    }
    .navigation {
      display: flex;
      flex-direction: column;
      align-items: center;
      .searchBox {
        .searchForm {
          text-align: center;
          .searchInput {
            font-size: 20px;
            border: 2px solid black;
            border-radius: 5px;
            padding: 5px;
          }
        }
      }
    }
  
  .productsBox {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 10px;
    
    @media (min-width: 550px) {
       max-width: 70%;
       margin-top: 0px;
     }
    

    .cartBox {
      color: black;
      padding: 10px;
      margin: 0 10px 10px 10px;
      box-shadow: 0 0 3px green;
      text-align: center;
      flex-basis: 100%;
      display: flex;

      @media (min-width: 768px) {
        flex-basis: 40%;
      }
      @media (min-width: 1025px) {
        flex-basis: 27%;
      }
 
      &:hover {
        background-color: #c2d0b2;
      }
    }
  }
}
