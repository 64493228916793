.productPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;

  @media (min-width: 700px) {
    justify-content: space-around;
    flex-direction: row;
    align-items: flex-start;
  }
  .productNavBar {
    padding: 10px;
    @media (min-width: 700px) {
      box-shadow: 0 0 15px #c2d0b2;
      
    }
  }
}

.containerProd {
  padding: 0;
  @media (min-width: 700px) {
    padding: 0 15px;
  }
}

.productBox {
  width: 100%;
  box-shadow: 0 0 15px #c2d0b2;
  padding: 20px;
  box-sizing: border-box;

  @media (min-width: 700px) {
    width: 70%;
  }

  .productInfo {
    .productInfoMain {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      @media (min-width: 770px) {
        flex-direction: row;
      }

      .infoMainRight {
        
        .tablePrice {
          margin: 0;
          border-radius: 10px;
          padding: 10px;
          font-size: 20px;
          border-spacing: 0;

          td,
          th {
            // border: 1px solid rgb(0, 0, 0);
            padding: 5px;
          }
        }
        .btnBuyProduct {
          margin-top: 30px;
          padding: 10px 60px;
          font-size: 20px;
          color: white;
          background-color: #ed1b1b;
          border: 1px solid #b3180c;
          border-radius: 5px;
          cursor: pointer;
          width: 100%;
          @media (min-width: 770px) {
            margin-left: 10px;
            width: auto;
          }
        }
        .buyMassage {
          display: none;
          text-align: left;
          padding-left: 10px;
        }
        .Show {
          display: block;
        }
      }
      .infoMainLeft {
        text-align: center;
        @media (min-width: 770px) {
          border-right: 1px solid #c2d0b2;
          padding-right: 15px;
          
        }
      }
    }
    .productInfoAdd {
      h4 {
        background-color: #c2d0b2;
        
        padding: 10px 14px;
        margin: 10px -20px;
      }
      .specification {
        max-width: 100%;
        border-spacing: 0;
        border: 1px solid black;

        td,
        th {
          border: 1px solid black;
          padding: 5px;
        }
      }
    }
  }
}


