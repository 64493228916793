.header {
    height: 100%;
    background: linear-gradient(to top,  rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(./general.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    

    padding-bottom: 20px;
    &-block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 10px;

    }
    .main-title-header {
        color: white;
        text-align: center;
        margin: 30px 0;
    }
}

.number {
    line-height: 3;
    @media (max-width:640px) {
        display: none;
    }
    .call {
        text-decoration: none;
        color: white;
        font-size: 18px;
        text-shadow: 1px 1px 1px #ffffff;
        &:hover {
            text-decoration: underline;
        }
    }
    .social-icon {
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .icon-insta {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
            box-shadow: 0px 3px 10px rgba(0,0,0,.25);
            line-height: 1.65;
            margin: 0 5px;

            .fa-instagram {
                color: white;
                font-size: 20px;
            }
        
        }
        .icon-fb {
            background-color: #3b5998;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            line-height: 1.85;
            margin: 0 5px;
            

            .fa-facebook-f {
                color: white;
                font-size: 16px;
            }
        }
        .icon-mail {
            background-color: #2785bb;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            line-height: 1.5;
            margin: 0 5px;
            

            .fa-envelope {
                color: white;
                font-size: 16px;
            }
        }

    }
}

.fa-phone-alt {
    color: rgb(58, 216, 27);
}

.main-title {
    color: white;
    text-align: center;
    margin: 70px 0;
}
.consultation {
    margin: 0 auto;
    text-align: center;
    .btn-consult {
        font-size: 18px;
    background-color:#50bb19;
    color: white;
    border-radius: 5px;
    font-family: emoji;
    padding: 9px;
    border: 1px solid #50bb19;
    cursor: pointer;
    transition: 0.3s;
        &:hover {
            padding: 12px;
            font-size: 20px;
            
        }



    }
}

.logo {
    position: relative;

    .logosvg {
        position: absolute;
        top: -60px;
        left: -110px;
    }
}

