.contactsBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 640px) {
        flex-direction: row;
        justify-content: space-around;
        
    }
    .contactInfo {
        ul {
            padding-left: 0;
            li {
                padding: 10px 0;
                .fas {
                    margin-right: 5px;
                    font-size: 20px;
                }
                .fa-phone-alt {
                    color: black;
                }
                a {
                    text-decoration: none;
                    color: black;
                    &:hover {
                        text-shadow: 0 0 2px black;
                    }
                }
            }
        }
    }
}