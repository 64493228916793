.portfolio {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .itemPhoto {
        margin-top: 20px;
        box-shadow: 0 0 10px white;
        cursor: pointer;
        flex-basis: 100%;
        @media (min-width: 450px) {
            flex-basis: auto;
        }
        .imgGalery {
            width: 100%;
            height: 250px;
            @media (min-width: 450px) {
            
                width: 350px;
                height: 300px;
            }
        }
    }
}
h1 {
    text-align: center;
    margin: 0;
    padding-top: 20px;
}
.photo-box {
    background-color: #c2d0b2;
}