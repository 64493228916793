.aboutPage {
    
    padding-bottom: 50px;

    .aboutBox {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .itemAboutBox {
            flex-basis: 400px;
            display: flex;
            align-content: stretch;
            align-items: center;
            font-size: 20px;
            font-weight: 600;

            .imgAboutPage {
                width: 100%;
            }

            
        }
    }

}

.advantagesPage {
    background-color: #2D2A25;
    padding: 30px 0;
    margin-bottom: 100px;

.advantagesBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    @media (min-width: 560px) {
        flex-direction: row;
    }
    .advantagesBox {
        padding: 10px;
        flex-basis: 50%;
        font-size: 20px;
        font-weight: 600;
        color: white;
        .imgCompany {
            width: 280px;
            height: 250px;
            @media (min-width: 500px) {
                width: 100%;
                height: auto;
            }
        }
        ul {
            padding: 0;
        }
    }
}
}