.navBox {
    font-size: 20px;
    width: 300px;
    .navTitleBox {
        h2 {
            display: inline;
            cursor: pointer;
        }
        .arrowNav {
            margin-right: 10px;
            padding: 5px 10px;
            display: inline;
            text-align: center;
            box-sizing: border-box;
            cursor: pointer;
            vertical-align: bottom;
            
            &:hover {
                border: 1px solid black;
                box-shadow: 0 0 2px black;
                
                
            }

            
        }

    }
    ul {
        padding: 0;
        margin-left: 20px;
        i {
          &:hover {
              text-shadow: 0 0 2px black;
          }
        }
    }
    a {
        text-decoration: none;
        color: black;
        cursor: pointer;
        
    }
}

.noneBar {
    display: none;
}
.closeNav {
    transform: rotate(180deg);
}

.activPage {
    border: 1px solid green;
}

.navItem {
    cursor: pointer;
}