.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.cartImg {
    text-align: center;
    width: 100%;
    height: auto;

}
.wrapperCart {
    margin: auto;
}