footer {
    background-color: black;
}
.footerBox {
    padding: 50px 0;
    background-color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    @media (max-width: 650px) {
        align-items: center;
        flex-direction: column;
    }

    .item {
        a {
            color: white;
            text-decoration: none;
            &:hover {
                color: rgb(140, 204, 140);
            }
            a {
                text-decoration: none;
                color: white;
            }
        }
        ul {
            color: white;
            li {
                a {
                    color: white;
                    text-decoration: none;
                }
            }
        }
    }
}

.social-icon {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    

    .icon-insta {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
        box-shadow: 0px 3px 10px rgba(0,0,0,.25);
        line-height: 1.65;
        margin: 0 5px;

        .fa-instagram {
            color: white;
            font-size: 20px;
        }
    
    }
    .icon-fb {
        background-color: #3b5998;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        line-height: 1.85;
        margin: 0 5px;
        

        .fa-facebook-f {
            color: white;
            font-size: 16px;
        }
    }
    .icon-mail {
        background-color: #2785bb;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        line-height: 1.5;
        margin: 0 5px;
        

        .fa-envelope {
            color: white;
            font-size: 16px;
        }
    }

}

.line {
    width: 90%;
    height: 3px;
    margin: 0 auto;
    background-color: whitesmoke;
}

.copyright {
    color: #b1b1b1;
    text-align: center;
    padding: 20px 0;
}