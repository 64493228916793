.modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.7);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: 0.5s;
    pointer-events: none;

}
.modal.active {
    opacity: 1;
    pointer-events: all;
}

.modal-content {
    padding: 12px;
    border-radius: 12px;
    background-color: #c2d0b2;
    
    transform: scale(0.5);
    transition: 0.4s all;

    section {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
     
      .form-group {
        width: 100%;
        margin-top: 10px;
        font-size: 18px;
        text-align: center;
      }
      .form-group input,
      .form-group textarea {
        width: 90%;
        font-size: 18px;
        margin-top: 5px;
        border: 1px solid green;
        padding: 5px;
      }
      
      textarea {
        resize: vertical;
      }
      
      button[type="submit"] {
        display: block;
        margin: 10px auto;
        background-color: green;
        width: 90%;
        border: none;
        outline: none;
        padding: 20px;
        font-size: 24px;
        border-radius: 8px;
        font-family: "Montserrat";
        color: rgb(0, 0, 0);
        text-align: center;
        cursor: pointer;
        transition: 0.3s ease background-color;
      }
      button[type="submit"]:hover {
        background-color: rgb(42, 196, 62);
      }
      #status {
        width: 90%;
        max-width: 500px;
        text-align: center;
        padding: 10px;
        margin: 0 auto;
        border-radius: 8px;
      }
      #status.success {
        background-color: rgb(211, 250, 153);
        animation: status 4s ease forwards;
      }
      #status.error {
        background-color: rgb(250, 129, 92);
        color: white;
        animation: status 4s ease forwards;
      }
      @keyframes status {
        0% {
          opacity: 1;
          pointer-events: all;
        }
        90% {
          opacity: 1;
          pointer-events: all;
        }
        100% {
          opacity: 0;
          pointer-events: none;
        }
      }


}

.modal-content.active {
    transform: scale(1);
}

