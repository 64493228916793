.advantages {
    &-box {
        background-color: #e3e3e3;

    }

    .title {
        font-size: 30px;
        text-align: center;
        margin: 0;
        padding: 20px 0;
        @media (min-width: 450px) {
            font-size: 40px;
            
        }
    }

    .facts {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 30px;
        @media (min-width: 560px) {
            flex-direction: row;
            justify-content: space-around;
        }

        .fact {
            width: 250px;
            height: 150px;
            border: 1px solid grey;
            text-align: center;
            padding: 15px 0;
            box-sizing: border-box;
            .num {
                font-size: 50px;

            }
            .text {
                font-family: sans-serif;
                font-size: 20px;
            }
        }
    }
}

.catalog {
    padding-bottom: 20px;
    &-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        

        .item {
            width: 350px;
            height: 250px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
            margin: 15px 0;
            color: white;
            font-size: 24px;
            text-align: center;
            line-height: 9;
            transition: 0.3s;
            &:hover {
                font-size: 25px;
            }


            a {
                text-decoration: none;
                color: white;
                &:hover {
                    color: #97dc8a;
                    text-shadow: 1px 1px 1px #97dc8a;
                }
            }

            &-plaster {
                background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../image/plaster.jpg');
                
    
            }

            &-material {
                background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../image/material.jpg');
              
                
            }

            &-glue {
                background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../image/kley.jpg');
               
            }

            &-priming {
                background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../image/grunt.jpg');
                
            }

            &-waterproofing {
                background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../image/waterprofing.jpg');
                
            }

            &-facadeDye {
                background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../image/dye2.jpg');
                
            }

            &-intoDye {
                background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../image/intoDye.jpg');
                
            }

            &-addition {
                background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../image/setka.jpg');
                
            }

            &-monting {
                background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../image/vynosnoy-montage.jpg');
                
            }


        }
    }
    .catalogTitle {
        text-align: center;
        font-size: 40px;
    }

}

.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 5px solid #c2d0b2;
    padding: 15px;
    @media (min-width: 620px) {
        flex-direction: row;
        border: 20px solid #c2d0b2;
        padding: 25px;
        
    }
    &-box {
        background-color: #236716;
        padding: 15px;
        @media (min-width: 756px) {
            padding: 40px;
            
        }
    }

    
    &-item {
        text-align: center;
        color: white;

        p {
            margin-bottom: 40px;
        }
        h2 {
            font-size: 40px;
        }

        .btnAbout {
            padding: 10px;
            cursor: pointer;
            font-size: 16px;
            margin-top: 20px;
            transition: 0.3s;
            border: none;
            font-weight: 600;
            background-color: white;
            text-decoration: none;
            color: black;
            &:hover {
                box-shadow: 0 0 10px #97dc8a;
            }
            @media (min-width: 756px) {
                
            }
        }
    }
    &-imgA {
        width: 200px;
        height: 200px;
        background-image: url('./house.jpeg');
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
        box-shadow: 0px 0px 100px #97dc8a;
        @media (min-width: 770px) {
            width: 300px;
            height: 300px;
                
        }
    }

}
.slider-box {
    margin-bottom: 20px;
}