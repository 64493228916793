.nav {
  text-align: center;
  position: relative;
  border: 1px solid white;
  transition: all 0.3s ease 0s;
  

  @media (max-width: 640px) {
    border: none;
  }
}
.menu{
  display:flex;
  flex-direction: row;
  align-items:center;
  justify-content: space-evenly;
  transition: all 1s ease 0s;
  padding: 0;
  li {
    list-style:none;
    text-decoration:none;
  }
}
.menu a{
  list-style:none;
  text-decoration:none;
  color: white;
  text-transform: uppercase;
  font-size: 15px;
  cursor: pointer;
  &:hover {
    font-size: 16px;
    text-shadow: 1px 1px 2px white;
  }

}
@media (max-width:640px){
  .menu{
    position: absolute;
    top: -200px;
    flex-direction: column;
    right: 0;
    left: 0;
    
    }
    .menu.show {
      top: 18px;
      text-align: center;
      display: block;
      margin: 0 auto;
      transition: all 1s ease 0s;
      position: relative;
    }
  .trigger{
    width: 40px;
    height: 30px;
    display:block;
    position: relative;
    margin:8px;
    cursor:pointer;
    margin-left: 90%;
  }

  .trigger span{
    width: 100%;
    background: rgb(255, 255, 255);
    position: absolute;
    height: 5px;
    margin-bottom: 5px;
    left: 0;
    top: 12px;
    transition: all 0.3s ease 0s;
  }

  .trigger.show span {
    transform: scale(0);
  }
  .trigger::after,
  .trigger::before {
    content: '';
    background-color: white;
    position: absolute;
    width: 100%;
    height: 5px;
    left: 0;
    transition: all 0.3s ease 0s;
  }

  .trigger::before {
    top: 0;

    
  }
  .trigger.show::before{
    transform: rotate(45deg);
    top: 13px;
  }
  .trigger.show::after{
    transform: rotate(-45deg);
    bottom: 13px;
  }

  .trigger::after {
    bottom: 0;
  }

  // .show{
  //   display: block;
  //   text-align: center;
     
  // }
}
